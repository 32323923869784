// main container 
.task-edit-view-container {
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 5px;
  margin-bottom: 10px;

  // left pannel 
  .left-panel {
    flex: 3;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: #0052cc;
      margin-bottom: 10px;
    }

    .section {
      margin-bottom: 20px;
      border-bottom: 1px dashed #dfe1e6;
      padding-bottom: 12px;

      h3 {
        font-size: 1.1rem;
        font-weight: 600;
        color: #344563;
        // margin-bottom: 8px;  
      }

      p {
        color: #42526e;
        line-height: 1.6;
      }
    }
  }

  // right pannel 
  .right-panel {
    flex: 2;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: #0052cc;
      margin-bottom: 20px;
    }

    .form-field {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      .form-field-row {
        display: flex;
        align-items: center;

        label {
          margin-right: 8px;
          width: 150px;
          font-size: 0.9rem;
          color: #344563;
          font-weight: 600;
        }

      }

      .p-error {
        margin-top: 4px;
        color: red;
        font-size: 10px;
        text-align: right;
      }
    }

  }
}

.p-invalid {
  border: 1px solid red !important;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.5) !important;
}